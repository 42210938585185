<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20704 aligncenter" src="@/assets/Cases/17/1.jpg" width="640" height="427"></div>
        <p>近来，常有投资人问<strong>为什么要办安提瓜护照？什么人群会选择办安提瓜护照？安提瓜护照的优势、用途等。</strong></p>
        <p>通过 近众多成功获得安提瓜护照的案例，小编将和大家具体分析安提瓜护照项目。</p>
        <p><strong>A女士为了让孩子去国际学校</strong></p>
        <p>A女士是公司高管，为摆脱应试教育对孩子带来的负面影响，决定让孩子一直接受国际化教育。孩子读的幼儿园是所在城市的双语幼儿园，而城中优质的国际学校几乎都需要外籍身份才有资格就读。</p>
        <p>为了让孩子接受国际化教育，A女士决定为孩子办一本外国护照。跃迁顾问了解了A女士的需求后，<strong>建议了性价比较高的安提瓜护照。</strong>20万美元可享三代移民。</p>
        <div class="pgc-img"><img loading="lazy" class="size-full wp-image-20705 aligncenter" src="@/assets/Cases/17/2.jpg" width="640" height="428"></div>
        <p>如今，A女士的孩子已经用安提瓜护照在当地国际学校就读了。孩子在国际化的教学环境中受益诸多，说着一口流畅的外语。接下来，A女士希望孩子能通过安提瓜作为英联邦国家的优势，顺利拿到英国学校的offer。</p>
        <div class="pgc-img"><img loading="lazy" class="alignnone size-full wp-image-20715 aligncenter" src="@/assets/Cases/17/3.jpg" width="640" height="432"></div>
        <p>安提瓜一直是欧美富豪偏爱的小众度假地，<strong>意大利前总理贝卢斯</strong>就在安提瓜购买了私人别墅。<strong>美国主持人奥普拉</strong>在安提瓜甚至有一座价值超过3千万美金的度假屋。<strong>这里环境优美，坐拥美丽的海滩、高品质度假村、是品质宜居之地。</strong></p>
        <p><strong>安提瓜护照项目优势</strong></p>
        <p>1.CRS税务透明机制下，资产配置解决方案；</p>
        <p>2.优越的税制，没有全球税、个人所得税、资本利得税、遗产税。加勒比海地区离岸金融中心；</p>
        <p>3.获得护照即可自由出入英国等多个英联邦国家，全球免签落地签150个国家，包含申根国家，如持有安提瓜护照可申请美国签证，一般可获发为期10年有效的多次往返签证；</p>
        <p>4.先批准，后投资；</p>
        <p>5.申请快捷简单：申请文件简单，客户无需面试，申请后4-6个月即可获得护照；</p>
        <p>6.申请条件宽松：无背景要求，对申请人学历、英语能力、管理经验、资金金额及资产来源均无要求；</p>
        <p>7.20万美元5年无息返还，即可三代移民；</p>
        <p>8.允许双重国籍，不要求申请人向原本国籍国家报告；</p>
        <p>9.轻松移民监：第一个五年内住满5天即可。</p>
        <p>10.度假天堂，优美的环境，品质宜居之地。</p>
        <div class="pgc-img"><img loading="lazy" class="alignnone size-full aligncenter" src="@/assets/Cases/17/4.jpg" width="638" height="370"></div>
        <p><strong>半月湾度假村项目优势：</strong></p>
        <p><strong>☑ 世界度假村开发商Replay集团主导开发</strong></p>
        <p><strong>☑ Rosewood奢华酒店品牌入驻管理</strong></p>
        <p><strong>☑ 少见的安提瓜半月湾海滩地理资源</strong></p>
        <p><strong>☑ 20万美元5年无息返还</strong></p>
        <p><strong>☑ 无额外税费</strong></p>
        <p><strong>☑ 享受政府申请费减半优惠政策</strong></p>
        <p><strong>☑ 安提瓜政府高度支持</strong></p>
        <p><strong>☑ 完善的登陆服务，为您排忧解难</strong></p>
        <div class="pgc-img"><img loading="lazy" class="alignnone size-full wp-image-20717" src="@/assets/Cases/17/5.jpg" width="640" height="218"></div>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases17",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>